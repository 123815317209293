import { Link } from "gatsby";
import React from "react";

function NotFoundPage() {
  return (
    <main className="h-screen flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold mb-4">404: Page Not Found</h1>
      <p className="text-lg mb-8">
        Sorry, the page you're looking for doesn't exist.
      </p>
      <Link to="/" className="px-4 py-2 bg-cyan-500 text-white rounded-md">
        Go back to Homepage
      </Link>
    </main>
  );
}

export default NotFoundPage;
